.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  margin-top: 34%;
  display: none;
  position: absolute;
  width: 113%;
  overflow: auto;
  background-color: #ffffff;
  border-radius: 12.5px;
  border: 1px solid #e5e5e5;
  border-top: 0px;

}

.menu {
  width: 250%;
  text-align: left;
  padding-top: 10%;
  margin-left: -50%;
}

.menu a {
  padding: 5% !important;

}

.menuaccount a {
  padding: 7% !important;

}

.dropdown-content a {
  padding: 15%;
  border-bottom: 1px solid #e5e5e5 !important;
  font-weight: bold;
  font-size: 90%;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  display: block;
  color: #000;
  padding: 5px;
  text-decoration: none;
}

.dropdown-content a:hover {
  background-color: #f4f4f4;
}

.dropdownbtn {
  border-radius: 50px;
  background: #fff !important;
  padding-right: 7.5%;
  padding-left: 7.5%;
  border: 1px solid #e5e5e5;

}

.dropdownbtn1 {
  border-radius: 50px;
  color: #606060 !important;
  padding-right: 7.5%;
  padding-left: 7.5%;
  border: 1px solid #e5e5e5;
}

.dropdownbtn:hover {
  background: #fff !important;
  padding-right: 7.5%;
  padding-left: 7.5%;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}


.header-dropdown-content {
  display: none;
  position: absolute;
  width: 100%;
  overflow: auto;
  background-color: #ffffff;
  border-bottom-left-radius: 12.5px;
  border-bottom-right-radius: 12.5px;
  font-size: 80%;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-top: 0px;
  color: black !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;

}