* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  zoom: 85%;
}

a {
  cursor: pointer !important;
}

@font-face {
  font-family: JakartaNFT;
  src: url("../../3.images/PlusJakartaSans-Bold.ttf");
}

@font-face {
  font-family: 'Satoshi-Variable';
  src: url("../../3.images/Satoshi-Variable.ttf");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}


body {
  margin-top: 0 !important;
  padding-top: 0 !important;
  font-family: "Satoshi-Variable", "Plus Jakarta Display", "Poppins", sans-serif;
  background-color: white;
  background-image: url("../../3.images/loading.gif");
  background-size: 4%;
  background-repeat: no-repeat;
  min-height: 100%;
  background-position: center center;
  background: #F6F6F7;
  color: black;
  font-size: 110%;
  overflow-x: hidden;
}

ul,
li {
  font-family: "Plus Jakarta Display", "Poppins", sans-serif;

}

#desktop {
  display: block;
}

#mobile {
  display: none;
}

@media only screen and (max-width: 767px) {
  body {
    background-size: 15%;
  }

  #desktop {
    display: none;
  }

  #mobile {
    display: block;
  }
}

.vertcenter {
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  /* More style: */
}

.bighover:hover {
  font-size: 110%;
  padding: 14px;
}

.pointer {
  position: relative;
  top: 0;
  transition: top ease 0.1s;
  cursor: pointer;
}

.pointer:hover {}

.newvertcenter {
  position: relative;
}

.newvertcenterelement {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.onlyvertcenter {
  display: flex;
  align-items: center;
}

.containerdivs {
  background: #2c2c2c;
  border: 1px solid #808080;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.267);
  border-radius: 12.5px;
}

.smalldivs {
  border: 1px solid #808080;
}

#loading {
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}

#upperinnermobile {
  font-size: 105%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 2.5%;
  padding-right: 2.5%;
}

hr {
  border-top: 1px solid #808080;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border: 1px solid #e5e5e5;

}

.hover {
  padding: 5%;
  border-radius: 12.5px;
}

.hover:hover {
  background-color: #f4f4f4;
}

.bg {
  background-image: url("https://64.media.tumblr.com/eddf38709b4ff8e4925de3360f603866/tumblr_pu0ndkstCx1uzwgsuo1_400.gif");
  background-size: cover;
}

a {
  cursor: pointer;
}