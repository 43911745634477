.headerdropdown {
    position: relative;
    width: 100%;
}

.headerdropdown-content {
    display: none;
    position: absolute;
    width: 100%;
    overflow: auto;
    background-color: #ffffff;
    border-radius: 12.5px;
    text-align: left;
    border: 1px solid #e5e5e5;
    border-top: 0px;
    color: black !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.headerdropdown-content a {
    padding: 2% !important;
    color: black !important;
    border-bottom: 1px solid #e5e5e5 !important;
    display: block;

}

.headerdropdown-content a:hover {
    background-color: #f4f4f4;
}