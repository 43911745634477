.logos {
    background-image: url("../../3.images/images/logos.png");
    background-repeat: repeat-x;
    background-size: contain;
}

.wrapper {
    position: relative;
}

.wrapper span {
    position: absolute;
    top: -2px;
    right: -2px;
    background-color: #949495;
    border-radius: 50%;
    font-weight: bold;
    font-size: 70%;
    color: #fff;
    width: 25px;
    height: 25px;
    justify-content: center;
    text-align: center;
}