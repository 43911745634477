.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 12.5px;
    outline: none;
    padding: 1%;
    width: 40%;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 100ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.DropDownContainer {
    width: 100%;
}

.DropDownHeader {
    border: 2px solid #e5e5e5;
    border-radius: 18px;
}

.DropDownList {
    border: 2px solid #e5e5e5;
    border-radius: 18px;
}


@media only screen and (max-width: 767px) {
    .mymodal {
        width: 90%;
        font-size: 120%;
    }
}