select {
    font-size: 80%;
    border-radius: 12.5px !important;
    border: 1px solid #e5e5e5 !important;
    padding: 5%;
    padding-left: 15%;
}

.selectdropdown {
    display: inline-block;
    position: relative;
}

.selectdropdown-content {
    display: none;
    position: absolute;
    width: 100%;
    overflow: auto;
    background-color: #ffffff;
    border-bottom-left-radius: 12.5px;
    border-bottom-right-radius: 12.5px;
    font-size: 80%;
    text-align: left;
    border: 1px solid #e5e5e5;
    border-top: 0px;
    color: black !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.selectdropdown-content a {
    padding: 7.5% !important;
    color: black !important;
    border-bottom: 1px solid #e5e5e5 !important;
}

.selectdropdown:hover .selectdropdown-content {
    display: block;
}

.selectdropdown-content a {
    display: block;
    color: #606060;
    padding: 5px;
    text-decoration: none;
}

.selectdropdown-content a:hover {
    background-color: #f3eaff;
}

.selectdropdownbtn {
    border-radius: 12.5px;
    color: #606060 !important;
    background: transparent !important;
    font-size: 80%;
    padding-right: 7.5%;
    padding-left: 7.5%;
    border: 1px solid #e5e5e5;
}

.selectdropdownbtn:hover {
    background: transparent !important;
    font-size: 80%;
    padding-right: 7.5%;
    padding-left: 7.5%;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 12.5px;
    border-top-right-radius: 12.5px;
}